import React, { useEffect, useState } from "react";
import { Row, Col, Button, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getInstallerDescription, getInstallerQlik, getInstallerQlikVersion, getInstallerBi, getBIManualUrl } from '../../actions/download/actions';
import { INSTALLER_CODE, SERVICE_CODES } from '../../common/constants';
import { Redirect } from 'react-router';
import { dictionaryGet } from '../../common/functions';

interface Installer {
    BI: any,
    QLIK: any;
}

export function DownloadQlikHistory() {
    const current = useSelector((state: AppState) => state.user.current);
    const thunkDispatch = useDispatch<ThunkDispatch<AppState, any, AnyAction>>();
    const [installers, setInstallers] = useState<Installer | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dictionary = useSelector((state: AppState) => state.dictionary);

    useEffect(() => {
        let installerObj: any = {};
        if (current.selectedCompany !== null) {
            let idCompany = current.selectedCompany.company.id;
            thunkDispatch(getInstallerDescription(INSTALLER_CODE.QLIK, idCompany)).then((res: any) => {
                installerObj.QLIK = JSON.parse(res.data);
                setInstallers(installerObj);
            });
        }
    }, [current.selectedCompany]);

    const downlaodVersion = (type: INSTALLER_CODE, fileName: "") => {
        if (current.selectedCompany !== null) {
            return thunkDispatch(getInstallerQlikVersion(current.selectedCompany.company.id, fileName)).then((res: any) => {
                window.open(res.payload.data);
            });
        }
    };

    const renderQlikHistory = () => {
        if (installers != null && installers.QLIK.legacy != null && installers.QLIK.legacy.length > 0) {
            let versions = installers.QLIK.legacy.map((h: any) => {
                return (

                    <p>
                        <a onClick={() => { downlaodVersion(INSTALLER_CODE.BI, h.fileName); }}>Scarica la versione {h.version}</a><br />
                    </p>

                )
            });

            return (
                <>
                { versions }
                </>
            );
        };
    }

    return (
        <div className="qlik-history">
            <div className="card-container dashboard-claim">
            <div className="card-body">
                <h4 className="simple-kpi-head">Versioni Qlik Sense disponibili solo per il ripristino di ambienti a versioni precedenti</h4>
            <div>
                {renderQlikHistory()}
            </div>
            </div>
            </div>
        </div>
    );
}